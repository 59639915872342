/* You can add global styles to this file, and also import other style files */
/**
 * Variables
 */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
body {
  background-color: #1F1E1E;
  color: #EDF2F7;
  -webkit-user-select: none;
          user-select: none;
  font-size: 14px;
}
.container-after-titlebar {
  height: calc(100% - 60px);
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}
.highlight-search {
  background-color: #FFD600;
  font-weight: bold;
  color: black;
}
[popover] {
  margin: inherit;
  position: inherit;
  background: inherit;
  color: inherit;
  border: inherit;
  inset: inherit;
  padding: inherit;
  height: inherit;
  overflow: inherit;
}
.titlebar .window-title {
  margin-left: 0 !important;
}
.titlebar .menubar {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.titlebar .menubar .menubar-menu-button {
  color: #787878;
}
.titlebar .menubar-menu-container {
  background-color: #222222 !important;
}
.titlebar .menubar-menu-container .action-item {
  background-color: #222222;
}
.titlebar .menubar-menu-container .action-item .action-menu-item {
  background-color: #222222 !important;
}
.titlebar .menubar-menu-container .action-item .action-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.titlebar .menubar-menu-container .action-label,
.titlebar .menubar-menu-container .action-label:hover,
.titlebar .menubar-menu-container .keybinding {
  color: #787878;
}
.titlebar .menubar-menu-container .action-label:not(.separator) {
  padding-left: 0;
}
.titlebar .menubar-menu-container .menu-item-icon {
  margin: 0;
}
.button {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  padding: 0px 11px;
  border-radius: 2px;
  height: 23px;
  line-height: 23px;
  border: 0.5px solid #ACACAC;
  background-color: #141414;
  color: #ACACAC;
  border: 1px solid #434343;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.button .icon {
  width: 16px;
  height: 16px;
}
.button:hover {
  background-color: #7D7D7D;
  color: #c6c6c6;
}
.button.submit {
  border-color: #41AD49;
  color: #41AD49;
}
.button.submit:hover {
  border-color: #5ec366;
  color: #5ec366;
}
.button.cancel {
  border-color: #D2232A;
  color: #D2232A;
}
.button.cancel:hover {
  border-color: #dd3239;
  color: #dd3239;
}
.button.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}
.button-icon {
  transition: color ease-in 0.1s;
}
.button-icon:hover {
  cursor: pointer;
  color: white;
}
.mat-datepicker-content {
  background-color: #1F1E1E;
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #EDF2F7;
}
.mat-calendar-body-label {
  color: #EDF2F7;
}
.mat-calendar-table-header {
  color: #EDF2F7;
}
.mat-calendar-table-header-divider::after {
  background-color: rgba(237, 242, 247, 0.12);
}
.mat-datepicker-content {
  color: #EDF2F7;
}
.mat-calendar-arrow {
  border-top-color: #EDF2F7;
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #EDF2F7;
}
.mat-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-stroked-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(237, 242, 247, 0.12);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(237, 242, 247, 0.3);
}
.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(237, 242, 247, 0.12);
}
.mat-calendar-body-selected {
  background-color: #177DDC;
}
.mat-datepicker-close-button {
  display: none !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0 !important;
  background-color: #177DDC !important;
  border-color: transparent !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #177DDC;
  border-color: transparent !important;
}
.mat-checkbox-checked .mat-checkbox-checkmark-path {
  stroke: #EDF2F7 !important;
}
.mat-checkbox-checkmark-path {
  stroke: #EDF2F7 !important;
}
.mat-checkbox .mat-checkbox-ripple {
  display: none;
}
.mat-checkbox-background {
  background-color: #141414;
  border: 1px solid #434343;
}
.mat-checkbox-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}
.mat-checkbox-label {
  font-size: 11px !important;
  color: #ACACAC;
  transition: color ease-in 0.1s;
}
.mat-checkbox-label:hover {
  color: white;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: #ACACAC !important;
}
.sketch-picker {
  min-width: 200px;
  background: none !important;
  box-shadow: none !important;
}
.sketch-swatches {
  border-color: rgba(0, 0, 0, 0.6) !important;
}
.sketch-fields .sketch-double color-editable-input .wrap input,
.sketch-fields .sketch-single color-editable-input .wrap input,
.sketch-fields .sketch-alpha color-editable-input .wrap input {
  border: 1px solid #434343 !important;
  box-shadow: none !important;
  outline: 0 !important;
  background-color: #141414 !important;
  display: inline-block;
  color: #ACACAC !important;
  border-radius: 2px !important;
  transition: all 0.2s !important;
}
.sketch-fields .sketch-double color-editable-input .wrap input::placeholder,
.sketch-fields .sketch-single color-editable-input .wrap input::placeholder,
.sketch-fields .sketch-alpha color-editable-input .wrap input::placeholder {
  opacity: 0.5 !important;
}
.sketch-fields .sketch-double color-editable-input .wrap input:hover,
.sketch-fields .sketch-single color-editable-input .wrap input:hover,
.sketch-fields .sketch-alpha color-editable-input .wrap input:hover,
.sketch-fields .sketch-double color-editable-input .wrap input:focus,
.sketch-fields .sketch-single color-editable-input .wrap input:focus,
.sketch-fields .sketch-alpha color-editable-input .wrap input:focus {
  border-color: #5d5d5d !important;
}
.sketch-fields .sketch-double color-editable-input .wrap span,
.sketch-fields .sketch-single color-editable-input .wrap span,
.sketch-fields .sketch-alpha color-editable-input .wrap span {
  color: #EDF2F7 !important;
}
color-alpha .alpha {
  background: white !important;
}
.ngx-contextmenu .dropdown-menu {
  border: solid 1px rgba(0, 0, 0, 0.5);
  background-color: #1F1E1E;
  border-radius: 2px;
  padding: 5px 0;
  margin: 0;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.33);
}
.ngx-contextmenu li {
  display: block;
}
.ngx-contextmenu li.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}
.ngx-contextmenu a {
  color: #EDF2F7;
  display: block;
  text-decoration: none;
  padding: 2px 15px;
  font-size: 12px;
}
.ngx-contextmenu a:hover {
  color: white;
  background-color: #121111;
}
.ngx-contextmenu .divider {
  margin: 2px 0;
  background-color: rgba(255, 255, 255, 0.1);
  height: 1px;
}
.hasSubMenu:before {
  margin-left: 5px;
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiPjxwYXRoIGZpbGw9IiNjY2NmZGIiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTMuMzQxIDYuNjU5bDIuNDQ3LTIuNDQ3YS4zLjMgMCAwIDAgMC0uNDI0TDMuMzQgMS4zNEEuMi4yIDAgMCAwIDMgMS40ODN2NS4wMzRhLjIuMiAwIDAgMCAuMzQxLjE0MnoiLz48L3N2Zz4=) !important;
}
.mat-dialog-container {
  background-color: #1F1E1E;
  border: 1px solid #000000;
  color: #EDF2F7;
  padding: 0 !important;
}
.mat-dialog-title {
  background: #27384D;
  color: #EDF2F7;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  padding: 0 5px;
  font-weight: normal;
  margin: 0 !important;
}
.mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
  max-height: 85vh !important;
}
.mat-dialog-actions {
  margin: 0 !important;
  min-height: 0 !important;
  justify-content: flex-end;
}
.mat-dialog-actions > .button {
  margin-left: 10px;
}
.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-header .actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-header .actions .action-icon {
  width: 16px;
  height: 16px;
  transition: color ease-in 0.1s;
}
.dialog-header .actions .action-icon:hover {
  cursor: pointer;
  color: white;
}
@font-face {
  font-family: 'Inconsolata-Regular';
  src: url('Inconsolata-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata-Black';
  src: url('Inconsolata-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata-Bold';
  src: url('Inconsolata-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata-ExtraBold';
  src: url('Inconsolata-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata-ExtraLight';
  src: url('Inconsolata-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata-Light';
  src: url('Inconsolata-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata-Medium';
  src: url('Inconsolata-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata-SemiBold';
  src: url('Inconsolata-SemiBold.ttf') format('truetype');
}
.input {
  border: 1px solid #434343;
  outline: 0;
  font-size: 14px;
  background-color: #141414;
  display: inline-block;
  padding: 0px 5px;
  height: 23px;
  color: #ACACAC;
  border-radius: 2px;
  transition: all 0.2s;
}
.input::placeholder {
  opacity: 0.5;
}
.input:hover,
.input:focus {
  border-color: #5d5d5d;
}
.input.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.33;
}
.input.error {
  border-color: #D2232A;
}
.label.disabled {
  opacity: 0.3;
}
.label.error {
  color: #D2232A;
}
.list-header {
  flex: 0 0 auto;
  height: 24px;
  width: 100%;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #27384D;
}
.list-header.shadow {
  position: relative;
  z-index: 1;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.list-header .title {
  margin: 0 5px;
  color: #EDF2F7;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-header .actions {
  flex: 0 0 auto;
  align-self: flex-end;
  margin-right: 5px;
}
.list-header .actions .action-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  font-size: 16px;
  margin-left: 2px;
  transition: color ease-in 0.1s;
}
.list-header .actions .action-icon:hover {
  color: #cad8e7;
}
.list-header .actions .action-icon.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}
.list-content {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0 5px;
  min-height: 24px;
}
.list-content .tag {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 10px;
  margin: 0 4px 4px 0;
  background: #252525;
  color: #606060;
  font-size: 14px;
  padding: 0 13px;
  transition: all ease-in 0.1s;
}
.list-content .tag:hover {
  background: #3f3f3f;
  color: #7a7a7a;
}
.list-content .tag.selected {
  background: #727272;
  color: #e0e0e0;
}
.mat-menu-panel {
  border: solid 1px rgba(0, 0, 0, 0.5);
  background-color: #1F1E1E;
  border-radius: 2px !important;
  margin: 0;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.33);
  min-height: auto !important;
}
.mat-menu-content:not(:empty) {
  padding: 5px 0 !important;
}
.mat-menu-item {
  padding: 2px 15px !important;
  font-size: 12px;
  height: auto !important;
  line-height: normal !important;
  color: #EDF2F7;
}
.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  color: white;
  background-color: #121111;
}
.mat-divider {
  margin: 2px 0;
  background-color: rgba(255, 255, 255, 0.1);
  height: 1px;
}
.placeholder-empty {
  margin: auto;
}
.placeholder-empty .text {
  margin: 10px;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
}
.placeholder-empty .text b {
  color: rgba(255, 255, 255, 0.4);
}
.placeholder-empty .icon {
  width: 16px;
  height: 16px;
}
.mat-accent .mat-slider-track-fill {
  background-color: #EDF2F7 !important;
}
.mat-accent .mat-slider-thumb {
  background-color: #EDF2F7 !important;
  border-color: rgba(0, 0, 0, 0) !important;
}
.mat-slider:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb {
  transform: scale(0.7) !important;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0) !important;
}
.mat-slider-thumb {
  right: -8px !important;
  bottom: -8px !important;
  width: 16px !important;
  height: 16px !important;
}
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #EDF2F7 !important;
}
.mat-tab-label {
  font-size: 14px;
  font-weight: normal;
  height: 24px !important;
  min-width: 10px !important;
  padding: 0 10px !important;
  color: rgba(255, 255, 255, 0.3);
  border-right: 1px solid #1F1E1E;
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.mat-tab-label-active {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.mat-ink-bar {
  display: none;
}
.mat-tab-header {
  border: none;
}
.mat-tab-labels {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.toast-container .ngx-toastr {
  padding: 15px 15px 15px 15px;
  box-shadow: 0 0 10px #000000;
}
.toast-error,
.toast-warning,
.toast-success,
.toast-info {
  background-image: none;
}
.toast-warning {
  background-image: none;
}
.toast-close-button:hover,
.toast-close-button:focus {
  outline: none;
}
.mat-slide-toggle .mat-slide-toggle-ripple {
  display: none !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #9C0000;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(12px, 0, 0) !important;
  border: none !important;
}
.mat-slide-toggle-thumb-container {
  width: 8px !important;
  height: 8px !important;
  top: 1px !important;
  left: 1px !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  border: 1px solid #6B6B6B !important;
  background-color: #1A1919 !important;
}
.mat-slide-toggle-bar {
  width: 22px !important;
  height: 10px !important;
  border: 1px solid #6B6B6B !important;
}
.mat-slide-toggle-thumb {
  height: 8px !important;
  width: 8px !important;
  border: none !important;
  background-color: #343534;
}
.mat-slide-toggle.mat-disabled {
  opacity: 0.78 !important;
}
.mat-slide-toggle.mat-disabled .mat-slide-toggle-bar {
  border: 1px solid #343534 !important;
}
.preset-settings-header {
  background: #303030;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4px;
  margin-top: 20px;
}
.preset-settings-header .label {
  font-weight: 500;
  font-size: 14px;
}
.preset-settings-title-big {
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  line-height: 12px;
  color: #DBDBDB;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 2px 0;
}
.preset-settings-title-small {
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;
  text-align: center;
  color: #ACACAC;
}
.settings-status-text {
  margin: 10px 0;
  color: #ACACAC;
  font-size: 14px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-status-text .icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.settings-status-text .icon.error {
  color: #D2232A;
}
.input.inherit {
  border-color: rgba(255, 214, 0, 0.5);
}
.input.inherit:hover,
.input.inherit:focus {
  border-color: #FFD600;
}
.checkbox.inherit .mat-checkbox-label {
  color: #FFD600 !important;
}
